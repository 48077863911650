<template>
  <!-- Banner -->
  <section
    :style="{
      backgroundImage:
        'url(' + require(`@/assets/images/${this.bgImage}`) + ')',
    }"
    class="find-sect md:py-8"
  >
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="py-8 find-text md:py-0">
        <div class="w-full md:w-3/4">
          <div class="mb-4 text-4xl font-bold text-blue">
            <slot name="title" />
          </div>
          <p class="mb-5 mr-5 text-base sm:text-xl">
            <slot name="subtitle" />
          </p>
          <p class="mb-5 text-xl text-blue">
            <slot name="description" />
          </p>
        </div>
        <div v-if="talkImg" class="talk-img">
          <img :src="require(`@/assets/images/${talkImg}`)" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner",
  props: {
    bgImage: {
      type: String,
      default: "",
    },
    talkImg: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.find-sect {
  background-size: cover;
  background-position: bottom center;
}
.find-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.find-text .talk-img {
  position: relative;
  left: auto;
  width: 250px;
  height: 250px;
}

/* TALK SECTION */
.talk-text {
  background: #f8f8f8;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  margin-left: 150px;
  padding-left: 120px;
}
.talk-img {
  position: absolute;
  top: 0;
  left: -150px;
  bottom: 0;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url(../assets/images/mask.jpg);
  padding: 15px;
  background-size: contain;
  background-position: center;
}
.talk-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .find-text {
    display: block;
  }

  .talk-text {
    padding: 25px;
    margin: 0;
  }
  .talk-img {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto 30px;
  }
}
</style>
