<template>
  <div
    @click="toggleDropDown"
    class="flex items-center justify-between h-full p-4 transition-colors duration-500 cursor-pointer"
    :class="[expanded ? 'text-white bg-blue' : 'text-blue bg-blue-100']"
  >
    <div class="">
      <slot name="header"> </slot>
    </div>

    <a v-if="!expanded" @click="toggleDropDown"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.001"
        height="10.922"
        viewBox="0 0 28.001 10.922"
      >
        <path
          id="Path_9349"
          data-name="Path 9349"
          d="M0,28,8.712,19.29a7.545,7.545,0,0,0,0-10.67L.091,0A26.524,26.524,0,0,1,4.05,13.927,26.4,26.4,0,0,1,0,28Z"
          transform="translate(28.001) rotate(90)"
          fill="currentColor"
          opacity="0.998"
        />
      </svg>
    </a>
    <a v-if="expanded"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.001"
        height="10.922"
        viewBox="0 0 28.001 10.922"
      >
        <path
          id="Path_9348"
          data-name="Path 9348"
          d="M831.1,508.03l8.712-8.711a7.545,7.545,0,0,0,0-10.67l-8.62-8.62a26.476,26.476,0,0,1-.091,28Z"
          transform="translate(-480.029 842.02) rotate(-90)"
          fill="currentColor"
          opacity="0.998"
        />
      </svg>
    </a>
  </div>
  <div
    class="overflow-hidden duration-500 transition-max-height max-h-0"
    :class="{ 'max-h-full': expanded }"
  >
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "Collapsible",
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
<style scoped></style>
