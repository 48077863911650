<template>
  <Banner bg-image="gray-bg.jpg" talk-img="warning-signs.png">
    <template v-slot:title>Suicide warning signs</template>
    <template v-slot:subtitle>
      Suicide does not have one single cause. Recognizing the warning signs and
      learning what to do next may help save a life.
    </template>
    <template v-slot:description>
      The following behavioural patterns may indicate possible risk for suicide
      and should be watched closely.</template
    >
  </Banner>
  <!-- Help Myself  -->
  <section class="pt-4 talk-sect my:my-12 md:py-1s4 gradient-white">
    <div class="max-w-6xl mx-auto sm:px-6">
      <div class="max-w-6xl mx-auto sm:px-6">
        <div
          class="my-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
        >
          Warning signs of possible risk for suicide:
        </div>

        <!-- 1 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">What they say</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mt-8">
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">1</div>
                    <div>
                      Talking about suicide and about what life would be like if
                      they were gone —for example, they may say things like,
                      “When I’m gone …” or ask questions like “What would it be
                      like if I wasn’t around?”
                    </div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">2</div>
                    <div>
                      Expressing feelings of worthlessness, for example saying,
                      “No one loves me.”
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="m-6 text-4xl font-bold text-blue">3</div>
                    <div>
                      Seeming hopeless about the future, saying things like
                      “What’s the use?”
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">What they start doing</div>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mt-8">
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">1</div>
                    <div>
                      Becoming preoccupied with giving away their belongings.
                    </div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">2</div>
                    <div>Drastic changes in behaviour.</div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">3</div>
                    <div>
                      Unnecessary risks, reckless and/or impulsive behaviour.
                    </div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">4</div>
                    <div>Increased use of alcohol and/or drugs.</div>
                  </div>
                  <div class="flex items-center">
                    <div class="m-6 text-4xl font-bold text-blue">5</div>
                    <div>Sleeping too much.</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">What they stop doing</div>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mt-8">
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">1</div>
                    <div>Loss of interest in personal appearance.</div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">2</div>
                    <div>Withdrawal from friends and/or social activities.</div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">3</div>
                    <div>Loss of interest in hobbies, work, school, etc.</div>
                  </div>
                  <div class="flex items-center">
                    <div class="m-6 text-4xl font-bold text-blue">4</div>
                    <div>Sleeping too little.</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Their general mood</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mt-8">
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">1</div>
                    <div>General hopelessness.</div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">2</div>
                    <div>Displaying extreme mood swings.</div>
                  </div>
                  <div class="flex items-center">
                    <div class="m-6 text-4xl font-bold text-blue">3</div>
                    <div>Unwillingness to connect with potential helpers.</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Their life experiences</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mt-8">
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">1</div>
                    <div>
                      Experience of a recent severe loss (especially a
                      relationship) or the threat of a significant loss.
                    </div>
                  </div>
                  <div class="flex items-center border-b-2 border-white-100">
                    <div class="m-6 text-4xl font-bold text-blue">2</div>
                    <div>
                      Experience or fear of a situation of humiliation of
                      failure.
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="m-6 text-4xl font-bold text-blue">3</div>
                    <div>
                      Previous history of suicide attempts, as well as violence
                      and/or hostility.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
      </div>
    </div>
  </section>
  <!-- SEEK HELP -->
  <section class="pt-10 md:pt-14">
    <div class="max-w-4xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        If the signs you observe appear numerous or severe, seek professional
        help.
      </div>
      <p class="text-xl text-center">
        For access to trained individuals 24/7 call:
      </p>
    </div>
    <div class="max-w-6xl px-4 py-5 mx-auto sm:px-6">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div class="tips-block">
          <div class="mb-4 text-2xl font-bold text-blue">
            The Distress Center
          </div>
          <a href="tel:4032664357" class="flex text-blue">
            <img src="../../assets/images/phone-icon.svg" class="mr-2" />
            <span>403-266-HELP</span>
          </a>
        </div>
        <div class="tips-block">
          <div class="mb-4 text-2xl font-bold text-blue">
            The Hope for Wellness Line
          </div>
          <a href="tel:855-242-3310" class="flex text-blue">
            <img src="../../assets/images/phone-icon.svg" class="mr-2" />
            <span>1-855-242-3310</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Collapsible from "@/components/Collapsible.vue";
export default {
  name: "SuicideWarningSigns",
  components: {
    Banner,
    Collapsible,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
.tips-block {
  background: #f8f8f8;
  border: 5px solid #3c5eaa;
  border-radius: 10px;
  padding: 30px;
}
</style>
